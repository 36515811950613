* {
  font-family: 'Inter', sans-serif !important;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 8px;
  height: 6px;
  cursor: pointer;
}
/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.9);
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.9);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #3e8bff;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.thumbnails > .thumbnail {
  border-radius: 8px;
}
.carousel-container .carousel-image {
  width: initial !important;
}
.carousel-container .next,
.carousel-container .prev {
  font-size: 32px;
}
